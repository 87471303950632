import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor, JwtInterceptor } from './helpers';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { LayoutService, AlertModule } from 'angular-admin-lte';

import { NgZorroAntdModule} from 'ng-zorro-antd';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';

import { NZ_I18N, es_ES } from 'ng-zorro-antd';
import { registerLocaleData, CommonModule } from '@angular/common';

import { NgxPermissionsModule } from 'ngx-permissions';

import es from '@angular/common/locales/es';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
import 'firebase/storage';

import { ChartModule } from '@syncfusion/ej2-angular-charts';

const config: SocketIoConfig = { url: environment.url_api, options: {} };

registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    ChartModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,    
    HttpClientModule,    
    NzDatePickerModule,
    AlertModule,
    BrowserAnimationsModule, 
    NgZorroAntdModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    NgxPermissionsModule.forRoot(), 
    SocketIoModule.forRoot(config), 
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    })
  ],
  providers: [
    { provide: NZ_I18N, useValue: es_ES },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    LayoutService
  ],
  exports: [
    CommonModule,
    NgxPermissionsModule 
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }